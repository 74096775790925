#Banner0_0.khd7fbrkld7-editor_css {
  background-image: url(https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jishu1.png);
}
#Banner0_1.khd9axmtn1b-editor_css {
  background-image: url(https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jishu4.png);
}

#Banner3_0.khd791eyzge-editor_css {
  background-image: url(https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jishu2.png);
}
#Banner3_1.khd8wb3mvc-editor_css {
  background-image: url(https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jishu3.png);
}
#Banner3_2.khd8yzqs1kc-editor_css {
  background-image: url(https://wwzh.oss-cn-hangzhou.aliyuncs.com/home%20page/static%20resource/jishu5.png);
}
